import React from "react"
import { Icon } from "utils/getIcon"
import { Element, scroller } from "react-scroll"

import ScrollAnimation from "react-animate-on-scroll"
import "utils/animate.css"

import {
  CoverBlock,
  Logo,
  Tags,
  CoverText,
  GetStartedButton,
  CoverPageContainer,
  CoverPageWrapper,
  InitialSection,
  CoverTwoGrid,
  CoverTextSection,
  FurtherInfo,
  ScrollButton,
  FadedText,
  MelbourneUniLogo,
  BottomButton,
  CoatWraper,
} from "./style"
import CoverIllustrations from "images/mysafety-cover-illustration-v2.png"
// import CoverIllustrations from "images/cover-illustrations.png"
import JCHLogo from "images/jch-logo.png"
import JCHCoat from "images/JCH_coat.png"
import PurpleBlotch from "images/purple-blotch.png"
import Cover1 from "images/cover-1.png"
import Cover2 from "images/cover-2.png"
import MelbourneUni from "images/uni-of-melbourne.logo.png"
import Cover3 from "images/cover-3.png"
import FadeIn from "react-fade-in"
import { PopUp } from "components/PopUp"
import { FooterLinks } from "components/FooterLinks"

const CoverScreen = () => {
  const singleArrow = <Icon name="arrow" />

  const scrollTo = element => {
    scroller.scrollTo(element, {
      duration: 1500,
      delay: 100,
      smooth: "easeOutCubic",
      offset: -50,
    })
  }

  return (
    <CoverPageContainer
      className="scrollableParentSelector"
      scrollableParentSelector
    >
      {/* <MelbourneUniLogo>
        <img src={MelbourneUni} alt="melbourne-uni-logo" />
      </MelbourneUniLogo> */}

      <PopUp />

      <CoverPageWrapper>
        <CoatWraper link="https://jch.unimelb.edu.au/">
          <img src={JCHCoat} />
        </CoatWraper>
        <InitialSection>
          <CoverBlock>
            <Logo src={JCHLogo} />
            <Tags>
              <h3>in relationships</h3>
              <h3>on campus</h3>
              <h3>in the community</h3>
            </Tags>
            <CoverText>
              MySafety is a program created for students at Janet Clarke Hall.
            </CoverText>
            <FadeIn transitionDuration={1300}>
              <GetStartedButton label="Get started" link="/questions" />
            </FadeIn>

            <ScrollButton
              label="or find out more"
              onClick={() => scrollTo("scroll-to-element")}
              noBackground
              icon={singleArrow}
            />
          </CoverBlock>
          <img src={CoverIllustrations} alt="" />
        </InitialSection>

        <FurtherInfo>
          <Element name="scroll-to-element" className="element">
            <CoverTwoGrid>
              <CoverTextSection>
                <h3>
                  University students are at increased risk of having an
                  unwanted sexual experience.
                </h3>

                <p>
                  It’s also common for them to get into relationships where they
                  feel unsafe. Some students worry about their own behaviour in
                  relationships or have pressured someone else into having sex.
                </p>
              </CoverTextSection>
              <img src={Cover1} alt="" />
            </CoverTwoGrid>
          </Element>

          <CoverTwoGrid>
            <img src={Cover2} alt="" />

            <CoverTextSection>
              <h3>
                When these things happen, it’s really important to get help
                early.
              </h3>
              <p>
                The problem is that many students don’t realize that what has
                happened to them, or what they have done to someone else, is not
                OK.
              </p>
            </CoverTextSection>
          </CoverTwoGrid>

          <FadedText>
            <ScrollAnimation
              animateIn="fadeIn"
              animateOnce={true}
              duration={1.2}
              offset={100}
              delay={0}
            >
              <h2>Nobody talks about it</h2>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeIn"
              animateOnce={true}
              duration={1.2}
              offset={100}
              delay={300}
            >
              <h2>It’s hard to know where to get help.</h2>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeIn"
              animateOnce={true}
              duration={1.2}
              offset={100}
              delay={600}
            >
              <h2>Problems get worse.</h2>
            </ScrollAnimation>
            <img className="purple" src={PurpleBlotch} alt="" />
          </FadedText>

          <CoverTwoGrid>
            <CoverTextSection>
              <h3>Looking for some help?</h3>
              <p>
                This website is for students at Janet Clarke Hall who are
                worried about something that happened during sex, who are
                concerned about their relationship, or are trying to help a
                friend. It is a safe, inclusive space.
              </p>

              <p className="underline bold">It is completely anonymous.</p>

              <p>
                It can help you to reflect on things that have happened, make
                decisions about what to do next, and find out what help is
                available. Ready to learn more?
              </p>

              <BottomButton label="Let's get started" link="/questions" />
            </CoverTextSection>
            <img src={Cover3} alt="" />
          </CoverTwoGrid>
          <FooterLinks />
        </FurtherInfo>
      </CoverPageWrapper>
    </CoverPageContainer>
  )
}

export default CoverScreen
