import React, { useContext } from "react"
import { Footer, LinkList, AvenueLogoLink } from "./style"
import { PopUpContext } from "context/PopUpContext.js"
import { ReactComponent as AvenueLogo } from "images/icons/made-by-avenue@2x.svg"

const FooterLinks = () => {
  const { togglePopUp, isPopUpOpen, popUpType } = useContext(PopUpContext)
  const acknowledgementContent = `
    <div>
        <h3>Acknowledgements</h3>
        <p><strong>JCH.MySafety</strong> is based on the original MySafety website which was funded by a University of Melbourne Student Services & Amenities Fee Grant. The original MySafety was developed by a team of experts from across the University, led by A/Prof Laura Tarzia, with generous input from students and staff at CASA House.</p>
        <p>This version was developed at the request of Janet Clarke Hall to support their students. A working group of senior staff, tutors and students from JCH contributed to its development.</p>
        <p>The “winding down” exercise and the Healthy Communication exercise were adapted from “Engaging with Perpetrators of Domestic Violence: Techniques for Early Intervention” by Kate Iwi and Chris Newman (Jessica Kingsley Publishers 2015).”</p>
        </div>
    `

  const privacyContent = `
    <div>
        <h3>Privacy Statement</h3>
        <p>MySafety has been designed to provide as much anonymity as possible for its users. It does not collect any identifying information about you. Your answers to the questions within the website cannot be linked to you personally. The site does not record your IP address or use cookies.</p>
    </div>
    `

  return (
    <Footer>
      <LinkList>
        <a
          href="#"
          onClick={() =>
            togglePopUp(true, "longForm", null, acknowledgementContent)
          }
        >
          Acknowledgements
        </a>
        <a
          href="#"
          onClick={() => togglePopUp(true, "longForm", null, privacyContent)}
        >
          Privacy Policy
        </a>
      </LinkList>
      <AvenueLogoLink to="https://avenue.design/">
        <AvenueLogo />
      </AvenueLogoLink>
    </Footer>
  )
}

export default FooterLinks
