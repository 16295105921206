import styled from "styled-components"
import { device } from "utils/breakpoints.js"
import { StandardGrid, TextWrapper } from "components/reusableComponents"
import AvenueLink from "components/AvenueLink"

export const Footer = styled(StandardGrid)`
  padding: 10px 0 40px;
  a {
    color: #fff;
    margin-right: 20px;
    text-decoration: underline;
  }
`

export const LinkList = styled(TextWrapper)`
  flex-direction: row;
  justify-content: flex-start;
  align-self: center;
`

export const AvenueLogoLink = styled(AvenueLink)`
  margin-right: auto;
  margin-left: -25px;
  margin-top: -25px;
  opacity: 1;
  transition: 100ms;
  justify-self:flex-start;
  @media ${device.desktop} {
    margin: 0;
    margin-left: auto;
  }
  svg {
    width: 153px;
    height: 80px;
    margin: 0;
  }

  :hover {
    opacity: 0.7;
  }
`
